import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CommaConvertPipe } from '../../../../../../pipes/comma-convert.pipe';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    CommaConvertPipe
  ],
  imports: [
    NgxMaterialTimepickerModule.setOpts('it-IT'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'it',
      extend: true
    })
  ],
  exports: [
    TranslateModule,
    NgxMaterialTimepickerModule,
    CommaConvertPipe
  ]
})
export class SharedLavorazioniModule { }
